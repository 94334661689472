@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.spline-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind other content */
}

#content {
  position: relative;
  z-index: 1;
}

#Hero {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #ffffff;
}

#pop {
  font-family: 'poppins', sans-serif;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  margin: auto;
  max-width: 80%;
}

.container {
  position: relative;
  width: 250px;
  margin: 20px auto;
}

.container .inp {
  width: 100%;
  padding: 10px;
  border: 1.5px solid blue;
  outline: none;
  border-radius: 5px;
  color: #fffcfc;
  background: #000;
  font-size: 1em;
}

.container .content {
  position: absolute;
  left: 0;
  margin-top: 20px;
  padding: 10px;
  text-transform: uppercase;
  font-size: 1em;
  pointer-events: none;
  color: #7d7d7d;
  transition: 0.5s;
}

.container input:valid ~ span,
.container input:focus ~ span {
  color: #ffffff;
  transform: translateX(10px) translateY(-7px);
  font-size: 0.9em;
  padding: 0 10px;
  background: rgb(41, 41, 41);
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

#button {
  border: #7d7d7d;
}

.hero-content {
  margin-top: 20px;
  font-style: italic;
}

.card {
  width: 300px;
  height: 400px;
  margin: 0 auto;
  background-color: #24233b;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0px 10px 10px rgb(73, 70, 92);
  transition: 0.5s;
}

.card:hover {
  transform: translateY(-7px);
  box-shadow: 0px 10px 10px black;
}

.top {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.circle {
  padding: 0 4px;
}

.circle2 {
  display: inline-block;
  align-items: center;
  width: 10px;
  height: 10px;
  padding: 1px;
  border-radius: 5px;
}

.red {
  background-color: #ff605c;
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: #00ca4e;
}

.header {
  margin: 5px;
  margin-top: 5px;
  border-radius: 5px;
}

#title2 {
  color: white;
  padding-left: 50px;
  font-size: 15px;
}

.code-container {
  text-align: center;
}

#code {
  width: 270px;
  height: 350px;
  resize: none;
  background-color: rgb(73, 70, 92);
  border-radius: 5px;
  border: none;
  color: white;
  padding: 10px;
}

#code:focus {
  outline: none !important;
}

/*Sasti masti (small devices override)*/
@media (max-width: 640px) {
  #Hero {
    font-size: 1.8rem;
    font-weight: 900;
    padding: 10px;
    margin: 0;
  }

  #Hero {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  #waitlistText {
    font-size: 1.3rem;
    padding: 8px;
    margin-top: 10px;
    line-height: 1.1;
  }

  .container {
    width: 100%;
    max-width: 80%;
  }

  .hero-content {
    margin-top: 10px;
  }

  .titpara {
    font-size: 1.7rem;
  }
}
 .foot {
  font-style: bold;
 }


.glassmorphism-button {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 255, 1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.glassmorphism-button:hover {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 0, 255, 1);
}


#button {
  position: relative;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid #0000FF;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

#button:hover {
  background: rgb(61, 106, 255);
  box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

#button:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

#button::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

#button:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}


